<template>
  <div>
    <app-login />
  </div>
</template>
<script>
import AppLogin from '@/components/Auth/Login'
export default {
  components: {
    AppLogin,
  },
}
</script>
